<template>
  <article :class="{ mobile: isPhone, ie11: isIE11 }">
    <Hero heading="Our Investment Plan">
      <template slot="image">
        <picture>
          <source
            srcset="@/assets/images/Our-plan/hero-our-plan.png, @/assets/images/Our-plan/hero-our-plan@2x.png 2x"
            media="(min-width: 750px)"
            alt="A group of Electrify America fast chargers glowing green at night."
          />
          <source
            alt="A group of Electrify America fast chargers glowing green at night."
            srcset="
              @/assets/images/Our-plan/hero-our-plan-mobile.png,
              @/assets/images/Our-plan/hero-our-plan-mobile@2x.png 2x
            "
          />
          <img
            class="hero-img"
            src="@/assets/images/Our-plan/hero-our-plan-mobile.png"
            alt="A group of Electrify America fast chargers glowing green at night."
          />
        </picture>
      </template>
    </Hero>
    <section class="investing">
      <div class="container">
        <div class="investing-content">
          <h2>Investing in an electric future</h2>
          <p class="heading-context">
            Over a 10-year period (made up of four 30-month “cycles”), Electrify America plans to invest $2 billion in
            Zero Emission Vehicle (ZEV) infrastructure, access, and education programs in the United States.
          </p>
          <div class="two-row">
            <div class="col-left">
              <h3>Highlights</h3>
              <decorative-list>
                <li>
                  Of this $2 billion, we’re investing $800 million in California, one of the largest ZEV markets in the
                  world.
                </li>
                <li>
                  We plan to invest $1.2 billion in the remainder of the United States, providing support to current
                  electric vehicle owners and those interested in learning more about the benefits of driving electric
                  vehicles.
                </li>
              </decorative-list>
              <p class="special">
                Electrify America’s National and California ZEV Investment Plans serve as a roadmap for what we do to
                drive ZEV adoption.
              </p>
            </div>
            <div class="col-right">
              <img
                loading="lazy"
                src="@/assets/images/Our-plan/investing-section.png"
                alt="A worker setting up an Electrify America charger."
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <h2 class="sr-only">Investment Plan Timeline</h2>
    <section class="cycle">
      <div class="two-row">
        <div class="col-left">
          <div class="container">
            <h2>
              Cycle 1 <br />
              <span>(January 2017–June 2019)</span>
            </h2>
            <h3>Building a roadmap</h3>
            <p>
              Over this first 30-month investment cycle, Electrify America invested in ZEV infrastructure, education,
              and access, thereby supporting increased adoption of ZEV technology.
            </p>
          </div>
        </div>
        <div class="col-right">
          <div class="container">
            <h3>Plans implemented</h3>
            <p>Electrify America implemented the following plans between January 2017 and June 2019:</p>
            <ul>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/California ZEV Investment Plan Cycle 1.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 1 California ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/Cycle 1 CA ZEV Invest Plan Supplement.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Supplement to the Cycle 1 California ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/National ZEV Investment Plan.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 1 National ZEV Investment Plan (PDF)</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="cycle cycle-2">
      <div class="two-row">
        <div class="col-left">
          <div class="container">
            <h2>
              Cycle 2 <br />
              <span>(July 2019–December 2021)</span>
            </h2>
            <h3>Current investments</h3>
            <p>
              The Cycle 2 National ZEV Investment Plan and Cycle 2 California ZEV Investment Plan detail Electrify
              America’s plans to invest between July 2019 and December 2021. These Cycle 2 investments are enabling
              millions of Americans to discover the benefits of electric driving. They also support the build-out of a
              nationwide network of convenient, reliable community and highway chargers.
            </p>
            <h3>Highlights</h3>
            <decorative-list>
              <li>
                Electrify America expects to install (or have under development) approximately 800 public charging
                stations with about 3,500 chargers by the end of Cycle 2.
              </li>
              <li>Marketing efforts are helping to raise consumer awareness of Zero Emission Vehicles.</li>
            </decorative-list>
          </div>
        </div>
        <div class="col-right">
          <div class="container">
            <h3>Plans under implementation</h3>
            <p>Electrify America is implementing the following plans between July 2019 and December 2021:</p>
            <ul>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/Cycle 2 California ZEV Investment Plan.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 2 California ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/Cycle 2 California ZIP Errata_0.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 2 California ZEV Investment Plan Errata (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/Cycle 2 National ZEV Investment Plan - Public Version vF.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 2 National ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="
                    require('../../../public/pdf/Summary of Electrify America National Cycle 2 ZEV Investment Plan 020619.pdf')
                  "
                  rel="noopener"
                  target="_blank"
                  >Summary of Cycle 2 National ZEV Investment Plan (PDF)</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="cycle cycle-3">
      <div class="two-row">
        <div class="col-left">
          <div class="container">
            <h2>
              Cycle 3 <br />
              <span>(January 2022-July 2024)</span>
            </h2>
            <h3>Planned investments</h3>
            <p>
              The Cycle 3 National ZEV Investment Plan and the Cycle 3 California ZEV Investment Plan detail Electrify
              America’s investments between January 2022 and July 2024. The planned investments will expand Electrify
              America’s network of Ultra-Fast charging stations in new communities and along additional travel
              corridors, strengthen education and awareness activities, and initiate Electrify America’s second “Green
              City” project in the city of Long Beach and the Los Angeles neighborhood of Wilmington.
            </p>
            <h3>Plan highlights</h3>
            <decorative-list>
              <li>
                New metro charging investments in the communities of Dallas, Detroit, Nashville, Orlando, Providence,
                Bakersfield, Stockton, and many other metropolitan areas across the nation
              </li>
              <li>
                Expand the nation’s largest open DC fast charging network to highways in all lower-48 states, creating
                two new cross-country routes and serving 28 new regional highway corridors
              </li>
              <li>$31-35 million of investment in transit, medium- and heavy-duty fleet charging in California</li>
            </decorative-list>
          </div>
        </div>
        <div class="col-right">
          <div class="container">
            <h3>Upcoming investment plans</h3>
            <p>Electrify America is implementing the following plans between January 2022 and July 2024:</p>
            <ul>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/cycle3_invesment_plan.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 3 California ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/cycle3_invesment_plan_summary.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 3 California ZEV Investment Plan Summary (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/cycle3_investment_plan_epa.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 3 National ZEV Investment Plan (PDF)</a
                >
              </li>
              <li>
                <a
                  class="link"
                  :href="require('../../../public/pdf/cycle_3_electrify_america_national.pdf')"
                  rel="noopener"
                  target="_blank"
                  >Cycle 3 National ZEV Investment Plan Summary (PDF)</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Card headlineLevel="h2" heading="Cycle 4" :isReverse="true" :maxWidth="1200" bgColor>
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="desktop-width"
            src="@/assets/images/Our-plan/cycle-section.png"
            alt="Closeup of a person fueling an electric vehicle."
          />
        </picture>
      </template>
      <h2 class="sub-heading">(2024–2026)</h2>
      <h3 class="headline3">Looking to the future</h3>
      <p>
        Electrify America will establish ZEV Investment Plans for two additional 30-month cycles to guide investments
        from 2022 through 2026.
      </p>
      <p>
        Developing new investment plans offers the opportunity to revisit past assumptions, update analytical models,
        monitor new technology and public policy developments, and consider evolving consumer expectations. Electrify
        America is a data-driven company and will attempt to use all pertinent information to identify the ZEV
        investments likely to assure its economic sustainability.
      </p>
    </Card>

    <HeroWithContentOverlay :overlayBG="false" fullWidthMobile>
      <template #image>
        <img
          loading="lazy"
          v-if="!isPhone"
          src="@/assets/images/Our-plan/middle-hero.png"
          alt="A group of Electrify America fast chargers in a tree-lined shopping center."
        />
        <img
          loading="lazy"
          v-if="isPhone"
          src="@/assets/images/Our-plan/middle-hero-mobile.png"
          alt="A group of Electrify America fast chargers in a tree-lined shopping center."
        />
      </template>
      <a
        rel="noopener"
        target="_blank"
        href="https://media.electrifyamerica.com/en-us/releases?model_id=&category_ids=239&event_ids=&release-categories=company+reports&locations=&year=&advanced_search=&sort_by=published-desc"
      >
        <h2>Read our company reports</h2>
        <p>
          See Electrify America’s latest quarterly <br v-if="!isPhone" />
          and annual reports.
        </p>
      </a>
    </HeroWithContentOverlay>

    <Card headlineLevel="h2" heading="Developing our plan" :isReverse="isPhone" :maxWidth="1200" bgColor>
      <template #image>
        <img
          loading="lazy"
          style="max-width: 520px"
          v-if="!isPhone"
          src="@/assets/images/Our-plan/involved-section@2x.png"
          alt="Three Electrify America DC fast chargers in a parking lot."
        />
        <img
          loading="lazy"
          v-if="isPhone"
          style="margin-top: 30px"
          src="@/assets/images/Our-plan/involved-section-mobile@2x.png"
          alt="Three Electrify America DC fast chargers in a parking lot."
        />
      </template>
      <p>To develop each ZEV Investment Plan, Electrify America invited feedback from:</p>
      <decorative-list>
        <li>
          <p>
            Federal, state, and local governments, Native American tribes, and the Department of Energy (DOE) National
            Laboratories
          </p>
        </li>
        <li>
          <p>Universities, think tanks, and technology companies</p>
        </li>
        <li>
          <p>Electric vehicle charging companies and electric vehicle driver associations</p>
        </li>
      </decorative-list>
      <h5>Thank you for helping shape our future plans</h5>
    </Card>
  </article>
</template>

<script>
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
import Card from '@/components/Card/Card.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'our-plan',
  metaInfo: {
    title: 'Our Zero Emission Vehicle Investment Plan| Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Between 2017 and 2027, Electrify America will have invested $2 billion in Zero Emission Vehicle (ZEV) infrastructure, access, and education in the U.S.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/our-plan/' }],
  },
  components: {
    Card,
    DecorativeList,
    HeroWithContentOverlay,
    Hero,
  },
  computed: {
    isIE11() {
      return navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
    },
    isPhone() {
      return this.$resize && this.$mq.below(850);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

img {
  width: 100%;
}
h1,
h2,
h3 {
  margin: 0 0 24px 0 !important;
}

h2,
.sub-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: 0.64px;
}

h3 {
  font-size: 24px;
  font-weight: $fw-regular;
  line-height: 1.5;
  letter-spacing: 0.48px;
}

p {
  margin: 0 0 24px 0;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

li {
  margin-bottom: 8px;
}

.hero-with-overlay {
  margin: 0px auto 70px;
  @media (min-width: 850px) {
    margin: 112px auto;
  }

  h2 {
    font-size: 32px;
    @media (min-width: 850px) {
      font-size: 48px;
    }
  }

  ::v-deep &__content {
    max-width: 100%;
    @media (max-width: 500px) {
      top: 50%;
    }
  }
  img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.col-left li {
  margin-bottom: 24px;
}

// Utility Classes

.two-row {
  display: flex;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 24px;
}

// Section Styling

.investing {
  background-color: $c-secondary-background;
  padding-top: 100px;
  @media (max-width: 750px) {
    padding-top: 40px;
  }

  .hero-img {
    margin-top: -175px;
    margin-bottom: 102px;
  }

  .investing-content {
    margin-left: 121px;
  }

  .heading-context {
    max-width: 760px;
    margin-bottom: 56px;
  }

  .col-right,
  .col-left {
    flex: 1;
  }

  .col-left {
    margin-right: 121px;

    :nth-child(3) {
      .col-right-inner p {
        margin-bottom: 54px;
      }
    }
  }

  .col-right {
    img {
      max-width: 372px;
      max-height: 372px;
      margin-top: 60px;
      margin-bottom: -50px;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
    }
  }

  .col-left-inner {
    img {
      width: 18px;
      height: 31px;
      margin-right: 18px;
    }
  }

  .special {
    margin-top: 70px;
  }
}

.cycle {
  h2 span {
    font-weight: $fw-medium;
  }
  padding: 96px 0 80px 0;

  .col-right,
  .col-left {
    flex: 1;
    .container {
      padding: 0 24px;
      max-width: 600px;
      margin: 0;
    }
  }

  .col-left {
    .container {
      padding-right: 88px;
      margin: 0 0 0 auto;
    }
  }

  .col-right {
    margin: auto auto;
    background-color: $c-secondary-background;

    .container {
      padding: 40px 40px 40px 64px;
      margin: 0 auto 0 0;
    }
  }

  .col-left-inner {
    img {
      width: 18px;
      height: 31px;
      margin-right: 18px;
    }
  }
}

.cycle-2 {
  background-color: $c-secondary-background;

  .col-right {
    background-color: $c-primary-background;
  }

  .col-left {
    .container {
      :nth-child(3) {
        margin-bottom: 48px;
      }
    }
  }
}

.cycle-3 {
  .coming-soon {
    font-weight: $fw-medium;
  }
  .col-left {
    h3:last-of-type {
      margin-top: 48px;
    }
    .container {
      :nth-child(3) {
        margin-bottom: 28px;
      }
    }
  }
}

.text-img {
  a {
    width: 100%;
    height: 100%;
  }
  text-shadow: 0px 0px 6px black, 0px 0px 12px black;
  padding: 100px 0;
  position: relative;
  .container {
    position: relative;
  }
  .content {
    padding-left: 100px;
    color: $c-primary-background;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    color: $c-primary-background;
    font-weight: $fw-regular;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 2.4px;
    margin-bottom: 8px !important;
  }

  p {
    color: $c-primary-background;
    font-size: 18px;
    letter-spacing: 0.9px;
    font-weight: $fw-medium;
  }

  img {
    display: block;
  }
}

.mobile {
  padding-top: 0 !important;

  h1 {
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 1.33;
    margin: 32px 0 18px !important;
  }

  .investing {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 21px;
    }

    .hero-img {
      margin-top: -200px;
      margin-bottom: 40px;
    }

    .investing-content {
      margin-left: 0;
    }

    .two-row {
      flex-direction: column;
    }

    .col-left {
      margin-right: 0;
    }

    .col-right {
      display: flex;
      justify-content: center;
      img {
        margin-bottom: 50px;
      }
    }
  }

  .cycle {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 21px;
      margin-bottom: 16px;
    }

    .col-left .container {
      padding-right: 0;
      padding-left: 0;
    }

    .col-right .container {
      padding: 24px;
    }

    padding: 56px 0;
    .two-row {
      flex-direction: column;
      padding: 0 20px;
    }
    .container {
      margin: 0 auto;
    }

    .col-right {
      background-color: $c-primary-background;

      .container {
        background-color: $c-secondary-background;
      }
    }
  }

  .cycle-2,
  .cycle-3 {
    .col-right .container {
      background-color: $c-primary-background;
    }
  }

  .cycle-3 {
    .col-right .container {
      background-color: $c-secondary-background;
    }
  }

  .text-img {
    padding-bottom: 56px;
    .content {
      position: absolute;
      width: 100%;
      padding: 20px;
      top: 0;
    }

    h2 {
      font-size: 32px;
      line-height: 42px;
      letter-spacing: 1.6px;
    }

    p {
      margin-top: 16px;
      max-width: 300px;
    }
  }
}

.ie11 {
  .cycle-2,
  .cycle-3 {
    .col-right {
      align-self: center;
    }
  }
}
.desktop-width {
  @media (min-width: 800px) {
    max-width: 500px !important;
  }
}
.headline3 {
  font-size: 24px;
  font-weight: $fw-regular;
  line-height: 1.5;
  letter-spacing: 0.48px;
}
</style>
